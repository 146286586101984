import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { TypeIcons } from './custom-icons';

@Injectable({
    providedIn: 'root'
})
export class CustomIconService {

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ){}

    public registerTypeIcons(): void {
        this.loadIcons(Object.values(TypeIcons), 'assets/images/types');
    }

    private loadIcons(iconKeys: string[], iconUrl: string): void {
        iconKeys.forEach(key => {
            this.iconRegistry.addSvgIcon(
                key,
                this.sanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`)
            );
        });
    }
}
