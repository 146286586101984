export enum TypeIcons {
    Class = 'class',
    Enum = 'enum',
    Event = 'event',
    Form = 'form',
    Key = 'key',
    None = 'none',
    String = 'string',
    Type = 'type',
    Logo = 'logo'
}
