import { Component, OnInit } from '@angular/core';

import { CustomIconService } from './shared/custom-icon.service';

@Component({
  selector: 'ps-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private customIconService: CustomIconService){}

  ngOnInit(): void{
    this.customIconService.registerTypeIcons();
  }
}
