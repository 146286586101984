import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'addQuoteBounderies'
})
export class AddQuoteBounderiesPipe implements PipeTransform{

    transform(value: string): string {
        return `\'` + value + `\'`;
    }

}