import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { IPhotoshopId } from './photoshop-id';
import { PhotoshopIdService } from './photoshop-id.service';

@Component({
  selector: 'ps-photoshop-search-ids',
  templateUrl: './photoshop-search-ids.component.html',
  styleUrls: ['./photoshop-search-ids.component.scss']
})
export class PhotoshopSearchIdsComponent implements OnInit {
  public searchControl: FormControl;
  public searching: boolean;
  public pageTitle = 'Action Manager IDs';
  public imageWidth = 30;
  public imageMargin = 1;
  public errorMessage: string;
  public displayedColumns: string[] = ['label', 'name', 'charId', 'stringId', 'typeId', 'category'];

  privateListFilter: string;
  get listFilter(): string {
      return this.privateListFilter;
  }
  set listFilter(value: string) {
    this.privateListFilter = value;
    this.filteredPhotoshopIds = this.listFilter ? this.performFilter(this.listFilter) : this.photoshopIds;
  }

  filteredPhotoshopIds: IPhotoshopId[];
  photoshopIds: IPhotoshopId[];

  constructor(private photoshopIdService: PhotoshopIdService, private activatedRoute: ActivatedRoute) {
    this.searchControl = new FormControl();
  }

  ngOnInit(): void{
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.photoshopIdService.getPhotoshopIds(id).subscribe({
      next: photoshopIds => {

        this.photoshopIds = photoshopIds;
        this.filteredPhotoshopIds = this.photoshopIds;

        this.searchControl.valueChanges
          .pipe(debounceTime(999))
          .subscribe(search => {

            this.searching = false;
            this.listFilter = search;

        });
      },
      error: err => this.errorMessage = err
    });
  }

  onSearchInput(): void {
    this.searching = true;
  }

  currentItems(): number{
    return this.filteredPhotoshopIds.length;
  }

  performFilter(filterBy: string): IPhotoshopId[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.photoshopIds.filter((psid: IPhotoshopId) =>
        psid.name.toLocaleLowerCase().indexOf(filterBy) !== -1 ||
        psid.charId.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }



}
