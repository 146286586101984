import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IPhotoshopId } from './photoshop-id';


@Injectable({
    providedIn: 'root'
})
export class PhotoshopIdService {

    charIdUrl = 'api/psid/ps_char_id_array.json';
    stringIdUrl = 'api/psid/ps_string_id_array.json';

    constructor(private http: HttpClient) { }


    getPhotoshopIds(id: string): Observable<IPhotoshopId[]> {
        let link: string;
        if (id === 'char') {
            link = this.charIdUrl;
        }
        if (id === 'string') {
            link = this.stringIdUrl;
        }
        return this.http.get<IPhotoshopId[]>(link).pipe(
            tap(data => console.log('All: ' + JSON.stringify(data))),
            catchError(this.handleError)
        );
    }

    // remove Observable<any> if things dont work
    private handleError(err: HttpErrorResponse) {
        // in a real world app, we may send the server error to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            // a client-side or network error occured. Handle it accordingly
            errorMessage = `An error occured: ${err.error.message}`;
        } else {
            // the backend returned an unsuccessful response code
            // the response body may contain clues as to what went wrong
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}