<div class='row'>
    <!-- <input type='text' [(ngModel)]='listFilter' /> -->
    <input type='text' [formControl]='searchControl' />
</div>
<div class='row'>
    <h5>Filtered by: {{listFilter}} </h5>
</div>
<div class='row'>
    <span *ngIf='photoshopIds && photoshopIds.length'>Results Found: {{ currentItems() }}</span>
</div>

<table mat-table [dataSource]='filteredPhotoshopIds' *ngIf='photoshopIds && filteredPhotoshopIds.length'>
    <!-- Label Column -->
    <ng-container matColumnDef='label'>
        <th mat-header-cell *matHeaderCellDef> Label </th>
        <td mat-cell *matCellDef='let element'> <img src='\assets\images\types\{{ element.category }}.png' [title]='element.category' [style.width.px]='imageWidth' [style.margin.px]='imageMargin'> </td>
    </ng-container>
    <!-- Category Column -->
    <ng-container matColumnDef='category'>
        <th mat-header-cell *matHeaderCellDef> Category </th>
        <td mat-cell *matCellDef='let element' class='mat-cell'> {{element.category}} </td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef='name'>
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef='let element' class='mat-cell'> {{element.name}} </td>
    </ng-container>
    <!-- CharID Column -->
    <ng-container matColumnDef='charId'>
        <th mat-header-cell *matHeaderCellDef> CharID </th>
        <td mat-cell *matCellDef='let element' class='mat-cell'> {{element.charId | addQuoteBounderies }} </td>
    </ng-container>
    <!-- StringID Column -->
    <ng-container matColumnDef='stringId'>
        <th mat-header-cell *matHeaderCellDef> StringID </th>
        <td mat-cell *matCellDef='let element'> {{element.stringId | addQuoteBounderies}} </td>
    </ng-container>
    <!-- TypeID Column -->
    <ng-container matColumnDef='typeId'>
        <th mat-header-cell *matHeaderCellDef> TypeID </th>
        <td mat-cell *matCellDef='let element' class='mat-cell'> {{element.typeId}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
    <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>