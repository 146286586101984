import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShellComponent } from './shell.component';
import { WelcomeComponent } from './home/welcome.component';
import { PhotoshopSearchIdsComponent } from './photoshop/photoshop-search-ids.component';
import { PhotoshopSearchIdGuard } from './photoshop/photoshop-search-id.guard';

const routes: Routes = [
  {
    path: '', component: ShellComponent,
    children: [
      {
        path: 'photoshop-search-ids/:id',
        canActivate: [PhotoshopSearchIdGuard],
        component: PhotoshopSearchIdsComponent
      },
      { path: 'home', component: WelcomeComponent},
      { path: '', redirectTo: 'home', pathMatch: 'full'},
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
